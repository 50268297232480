




















.collect-container {
  box-sizing: border-box;
  min-height: 100vh;
  padding-top: 90px;
  background-color: #f8f8f8;
  overflow: hidden;
  .form-container {
    box-sizing: border-box;
    min-width: 960px;
    width: 56.25vw;
    padding: 72px 0 80px;
    margin: 100px auto;
    background-color: #fff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3.title {
      margin-bottom: 68px;
      line-height: 1.4;
      color: #333;
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }
    /deep/ .submit-btn {
      box-sizing: border-box;
      width: 360px;
      height: 60px;
      line-height: 60px;
      margin: 57px auto 0;
      text-align: center;
      border-radius: 4px;
      border: 1px solid #ff6600;
      background-color: #fff;
      color: #ff6600;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: #ff6600;
        color: #fff;
      }
    }
    /deep/ .el-form {
      .el-form-item {
        .el-form-item__label {
          float: left;
          padding-left: 19px;
          font-size: 18px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          &::before {
            content: '';
            margin-right: 0;
          }
        }
        .el-input__inner {
          height: 52px;
          border-radius: 4px;
          &::placeholder {
            font-size: 18px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #999999;
          }
          &:focus {
            border: 1px solid #ff6600;
          }
        }
        .el-textarea {
          textarea {
            &::placeholder {
              font-size: 18px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #999999;
            }
            &:focus {
              border: 1px solid #ff6600;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .collect-container {
    .form-container {
      width: 1080px;
    }
  }
}
