.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.el-form {
  margin: 0 50px;
}
.el-form .el-form-item {
  width: 300px;
  margin-right: 40px;
}
.el-form .el-form-item:nth-child(3n) {
  margin-right: 0;
}
.el-form .el-form-item:nth-last-child(2) {
  width: 100%;
}
.el-form .el-form-item .el-select {
  width: 100%;
}
.el-form .el-form-item /deep/ .el-input__inner {
  height: 52px;
}
.el-form .el-form-item .el-textarea /deep/ textarea {
  height: 120px;
}
.el-form .el-form-item .el-cascader {
  display: block;
}
.el-form .learn_item {
  margin-bottom: 1.0416666666666665vw;
  width: 100%;
}
.el-form .submit-btn {
  margin-top: 80px !important;
}
 /deep/ .form-container {
  min-width: 1080px !important;
}
