






























.collect-success-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .8);
  .modal-box {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 520px;
    height: 400px;
    padding-top: 60px;
    background-color: #fff;
    border-radius: 16px;
    .close-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 16px;
      height: 16px;
      background-image: url(https://mktv-in-cdn.mockuai.com/16270256543822994.png);
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .icon-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 92px;
      height: 92px;
      margin-bottom: 15px;
      border-radius: 50%;
      background-color: #ff6600;
      .icon {
        width: 59px;
        height: 42px;
      }
    }
    .title {
      margin-bottom: 10px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
    }
    .text {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 25px;
    }
    .btn {
      position: absolute;
      bottom: 60px;
      left: 50%;
      transform: translateX(-50%);
      width: 240px;
      height: 52px;
      line-height: 52px;
      text-align: center;
      border-radius: 30px;
      background-color: #ff6600;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, .8);
      cursor: pointer;
      &:hover {
        color: #fff;
      }
    }
  }
}
